import { Settings } from 'http2';
import { useRef } from 'react';

const useSliderHook = (customSettings = {}) => {
  const sliderRef = useRef(null);

  const settings = {
    infinite: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    ...customSettings,
  } as Settings;

  const onPrevClick = () => {
    if (sliderRef?.current?.slickPrev) {
      sliderRef?.current?.slickPrev();
    }
  };

  const onNextClick = () => {
    if (sliderRef?.current?.slickNext) {
      sliderRef?.current?.slickNext();
    }
  };

  return {
    sliderRef,
    settings,
    onPrevClick,
    onNextClick,
  };
};

export default useSliderHook;
