import React, { ReactNode } from 'react';
import Slider from 'react-slick';
import SliderArrowBack from '@/assets/svgExports/SliderArrowBack';
import SliderArrowForward from '@/assets/svgExports/SliderArrowForward';
import useBasicSliderHook from '../../hooks/useBasicSliderHook';

interface BasicSliderProps {
  showArrows?: boolean;
  arrowColor?: string;
  arrowCommonClasses?: string;
  arrowLeftClasses?: string;
  arrowRightClasses?: string;
  customArrowLeft?: any;
  customArrowRight?: any;
  showDots?: boolean;
  children?: ReactNode | undefined;
  customSettings?: any;
}

const BasicSlider: React.FC<BasicSliderProps> = ({
  showArrows = false,
  arrowColor = '#000000',
  arrowCommonClasses = '',
  arrowLeftClasses = '',
  arrowRightClasses = '',
  customArrowLeft = null,
  customArrowRight = null,
  showDots = true,
  children = null,
  customSettings = {},
}) => {
  const { sliderRef, settings, onPrevClick, onNextClick } =
    useBasicSliderHook(customSettings);

  const deafultArrowClasses =
    'tw-absolute -tw-translate-y-1/2 tw-top-[50%] tw-cursor-pointer';
  const defaultArrowLeftClasses = 'tw-left-[-24px]';
  const defaultArrowRightClasses = 'tw-right-[-24px]';

  return (
    <div className="tw-relative">
      {showArrows && (
        <span
          className={`${deafultArrowClasses} ${defaultArrowLeftClasses} ${arrowCommonClasses} ${arrowLeftClasses}`}
          onClick={onPrevClick}
        >
          {customArrowLeft ? customArrowLeft : <SliderArrowBack color={arrowColor} />}
        </span>
      )}
      <Slider
        ref={(slider) => (sliderRef.current = slider)}
        {...settings}
        dots={showDots}
      >
        {children}
      </Slider>
      {showArrows && (
        <span
          className={`${deafultArrowClasses} ${defaultArrowRightClasses} ${arrowCommonClasses} ${arrowRightClasses}`}
          onClick={onNextClick}
        >
          {customArrowLeft ? customArrowRight : <SliderArrowForward color={arrowColor} />}
        </span>
      )}
    </div>
  );
};

export default BasicSlider;
