import store from '@/redux/store';
import { IS_MOBILE } from '@/utils/checkClientDeviceType';
import { MAX_MOBILE_WIDTH } from '@/utils/constants';
import styled from 'styled-components';

const storeInfo = store.getState().storeReducer.store;

function slideInAnimate() {
  return `
    animation-name: slide-in;
    animation-duration: 0.5s;
    transform : translateX(50px);

    @keyframes slide-in {
      from {
          transform : translateX(0px);
      }
      to {
          transform : translateX(50px);
      }
    }
    `;
}

function slideOutAnimate(trasnlationDist) {
  return `
    animation-name: slide-out;
    animation-duration: 0.50s;

    @keyframes slide-out {
      from {
          transform : translateX(0px);
      }
      to {
         transform : translateX(${trasnlationDist + 58}px);
      }
    }
    `;
}

export const Container = styled.article`
  padding: 4rem 0 2rem;
  width: '65vw';
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: auto;
  box-sizing: border-box;
  @media screen and (max-width: 767px) {
    padding-top: 0;
    width: 100vw;
  }
`;

export const Heading = styled.h3`
  text-align: center;
  width: 100%;
  font-size: 40px;
  color: ${(props) => props.fontColor || '#000000'};

  @media screen and (min-width: 767px) {
    font-size: 40px;
    margin-bottom: 3rem;
  }

  @media screen and (max-width: 766px) {
    font-size: 28px;
    margin-bottom: 2rem;
  }
`;

export const Listing = styled.div`
  width: 100%;
  max-width: 1000px;
  display: grid;
  grid-template-rows: auto;
  justify-items: center;
  box-sizing: border-box;

  @media screen and (min-width: 767px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    row-gap: 2rem;
    column-gap: 1.5rem;
    padding: 0 2rem;
  }

  @media screen and (max-width: 766px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 1.5rem;
    column-gap: 1rem;
    padding: 0 1rem;
  }
`;

export const PromoSliderContainer = styled.div`
  width: 100%;
  max-width: 1000px;
  padding: 0 2rem;
  margin-top: 20px;
  margin-bottom: 38px;

  @media screen and (max-width: 766px) {
    padding: 0 1rem;
  }
`;

export const ListingHeading = styled.h2`
  color: #000000;
  font-weight: 600;
  margin-bottom: 0.8rem;
  scroll-margin: 50px 0 0 0;

  @media screen and (min-width: 767px) {
    font-size: 28px;
    padding: 0 2rem;
  }

  @media screen and (max-width: 766px) {
    font-size: 20px;
    padding: 0 1rem;
  }
`;

export const AdWidgetContainer = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) =>
    props.showFilterDrawer && IS_MOBILE ? 'overflow: hidden' : 'overflow: auto'};
  ${(props) =>
    props.showFilterDrawer === null
      ? ''
      : props.showFilterDrawer
        ? slideInAnimate()
        : slideOutAnimate(props.trasnlationDist)};
`;

export const FilterDrawerWrapper = styled.div`
  display: flex;
  border-right: 1px solid #eaeaea;
  position: sticky;
  height: 100vh;
  width: 300px;
  display: ${(props) => (props?.showFilterDrawer ? 'unset' : 'none')};
  @media screen and (max-width: ${MAX_MOBILE_WIDTH}px) {
    width: 100vw;
    top: 0px;
  }
`;

export const FilterDrawerMobileCtaContainer = styled.div`
  all: unset;
  display: flex;
  position: absolute;
  bottom: 0%;
  height: 48px;
  width: 100vw;
  justify-content: center;
  align-itemns: center;
  background: white;
`;

export const FilterDrawerMobileCta = styled.button`
  all: unset;
  display: flex;
  height: 48px;
  width: 50vw;
  justify-content: center;
  align-items: center;
  background: white;
  border: 1px solid #e2e2e2;
  :last-child {
    border-left: none;
  }
`;
export const FilterDrawerMobileCtaApply = styled.button`
  all: unset;
  display: flex;
  height: 48px;
  width: 50vw;
  justify-content: center;
  align-items: center;
  background: white;
  border: 1px solid #e2e2e2;
  background: ${storeInfo?.theme?.colors?.primary_color ?? '#222'};
  color: #fff;
  :last-child {
    border-left: none;
  }
`;
