import { useEffect, useState } from 'react';
import BasicSlider from 'src/components/BasicSlider';
import PromoSlide from '../PromoSlide';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import { useDispatch, useSelector } from 'react-redux';
import { getPromosByTypeAndTypeId } from 'src/redux/actions';
import { RootState } from 'src/redux/reducers';
import { useFeatureLocks } from 'src/hooks/useFeatureLocks';

const PromoSlider = ({ type, typeId, customClasses = '' }) => {
  const dispatch = useDispatch();
  const [promosData, setPromosData] = useState([]);

  const { storeId, userId } = useSelector((state: RootState) => ({
    storeId: state.storeReducer.store.store_id,
    userId: state.userReducer.data?.user_id || 0,
  }));

  const { couponsFeatureStatus } = useFeatureLocks();
  const isCouponsFeatureLocked = !!couponsFeatureStatus?.isLocked;

  useEffect(() => {
    if (type && typeId >= 0 && !isCouponsFeatureLocked) {
      getSliderPromos();
    }
  }, []);

  function getSliderPromos() {
    typeId = Number(typeId);
    if (isNaN(typeId) || typeId < 0) {
      return;
    }
    const data = {
      type: type,
      type_ids: [typeId],
      store_id: storeId,
      user_id: userId,
    };

    dispatch(getPromosByTypeAndTypeId(data, (data) => setPromosData(data)));
  }

  return (
    !!promosData?.length && (
      <div
        className={`tw-w-full ${IS_DESKTOP ? 'tw-mb-[24px]' : 'tw-mb-[36px]'} ${customClasses}`}
      >
        <BasicSlider
          showArrows={IS_DESKTOP && promosData?.length > 1}
          showDots={!IS_DESKTOP}
        >
          {promosData.map((promoData, index) => (
            <PromoSlide promoData={promoData} key={index} />
          ))}
        </BasicSlider>
      </div>
    )
  );
};

export default PromoSlider;
